
import { Component, Vue } from 'vue-property-decorator'
import * as echarts from 'echarts'
import { WeatherDetail } from '../../types/ecologyMonitor.d'
import { directionTransition } from '../../utils/formatData'

@Component
export default class WeatherHistory extends Vue {
  private paramList: Array<{ icon: string; name: string; value: string; data: Array<string> }> = [
    {
      icon: 'icon-icon_wendu',
      name: '温度(°C)',
      value: 'temperature',
      data: []
    }, {
      icon: 'icon-icon_shidu',
      name: '湿度(%)',
      value: 'humidity',
      data: []
    }, {
      icon: 'icon-icon_jiangyuliang',
      name: '降雨量(mm)',
      value: 'precipitation',
      data: []
    }, {
      icon: 'icon-icon_qiya',
      name: '气压(pa)',
      value: 'pressure',
      data: []
    }, {
      icon: 'icon-icon_fengxiang',
      name: '风向',
      value: 'direction',
      data: []
    }, {
      icon: 'icon-icon_pm25',
      name: 'PM2.5(μg/m³)',
      value: 'pm25',
      data: []
    }, {
      icon: 'icon-icon_zaoyin',
      name: '噪音(db)',
      value: 'noise',
      data: []
    }, {
      icon: 'icon-icon_fengsu',
      name: '风速(m/s)',
      value: 'speed',
      data: []
    }, {
      icon: 'icon-icon_fuyanglizi',
      name: '负氧离子(个/cm³)',
      value: 'negativeOxyge',
      data: []
    }, {
      icon: 'icon-icon-guangzhao',
      name: '光照(Lux)',
      value: 'lux',
      data: []
    }, {
      icon: 'icon-icon-pm10',
      name: 'PM10(μg/m³)',
      value: 'pm10',
      data: []
    }, {
      icon: 'icon-icon-eryanghuatan',
      name: '二氧化碳(ppm)',
      value: 'co2',
      data: []
    }, {
      icon: 'icon-icon-taiyangzongfushe',
      name: '太阳总辐射(mwh/cm²)',
      value: 'zfs',
      data: []
    }
  ]

  private collectTime: Array<string> = []

  private deviceData: any = {}
  private showNumber = 0
  private finds = []
  private dataList: Array<WeatherDetail> = []
  private echartSelect = ''
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private typeColor = [
    'rgba(255, 158, 88, 1)',
    'rgba(19, 177, 244, 1)',
    'rgba(19, 177, 244, 1)',
    'rgba(90, 112, 220, 1)',
    'rgba(54, 203, 203, 1)',
    'rgba(109, 212, 0, 1)',
    'rgba(255, 113, 120, 1)',
    'rgba(54, 203, 203, 1)',
    'rgba(24, 144, 255, 1)',
    'rgba(255, 158, 88, 1)',
    'rgba(109, 212, 0, 1)',
    'rgba(24, 144, 255, 1)',
    'rgba(255, 158, 88, 1)'
  ]

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private echart: any

  get deviceNumber () {
    return this.$route.params.deviceNumber as string
  }

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    this.getDetail()
    this.getData()
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const val = this.$dayjs().format('YYYY-MM-DD')
    const vals = this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD')
    this.searchInfo.dateRange = [vals, val]
  }

  getDetail () {
    this.$axios.get(this.$apis.ecologyMonitor.selectWeatherInfoByDeviceNumber, {
      deviceNumber: this.deviceNumber
    }).then((res) => {
      this.deviceData = res
      if (this.deviceData.direction) {
        this.deviceData.direction = directionTransition(Number(this.deviceData.direction))
      }
      for (const key in this.deviceData) {
        this.paramList.forEach(item => {
          if (item.value === key && this.deviceData[key]) {
            this.showNumber++
          }
        })
      }
    })
  }

  getData () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.ecologyMonitor.selectWeatherDataByPage, {
        deviceNumber: this.deviceNumber,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.finds = res.finds
        this.echartSelect = res.finds[0]
        this.dataList = res.weatherInfo.list
        this.getChartData()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  getChartData () {
    this.paramList.forEach(item => {
      item.data = []
    })
    this.collectTime = []
    const index = this.paramList.findIndex((item: any) => item.value === this.echartSelect)
    const title = this.paramList[index].name
    const color = this.typeColor[index]
    if (this.dataList.length > 0) {
      this.dataList.forEach(item => {
        this.paramList[0].data.push(item.temperature)
        this.paramList[1].data.push(item.humidity)
        this.paramList[2].data.push(item.precipitation)
        this.paramList[3].data.push(item.pressure)
        this.paramList[4].data.push(item.direction)
        this.paramList[5].data.push(item.pm25)
        this.paramList[6].data.push(item.noise)
        this.paramList[7].data.push(item.speed)
        this.paramList[8].data.push(item.negativeOxyge)
        this.paramList[9].data.push(item.pm10)
        this.paramList[10].data.push(item.co2)
        this.paramList[11].data.push(item.lux)
        this.paramList[12].data.push(item.zfs)
        this.collectTime.push(item.collectTime)
      })
    }
    this.draw(title, [color], this.collectTime || [], this.paramList[index].data || [])
  }

  changeEchart () {
    this.getChartData()
  }

  draw (title: string, color: Array<string>, xData: Array<string>, yData: Array<string>) {
    this.$nextTick(() => {
      this.echart = echarts.init(this.$refs.echart)
      const name = title.indexOf('(') > 0 ? title.substring(0, title.indexOf('(')) : title
      const option: any = {
        tooltip: {
          trigger: 'axis'
        },
        grid: {
          top: 80,
          left: 40,
          right: 30,
          bottom: 0,
          containLabel: true
        },
        title: {
          text: title,
          textStyle: {
            fontSize: 18,
            fontWeight: 400,
            color: '#000000'
          }
        },
        legend: {
          icon: 'rect',
          top: 40,
          left: 0,
          itemWidth: 20,
          itemHeight: 1,
          itemGap: 5,
          textStyle: {
            color: '#8C8C8C'
          }
        },
        backgroundColor: 'transparent',
        color: color,
        xAxis: {
          type: 'category',
          axisTick: {
            alignWithLabel: {
              boundaryGap: true
            }
          },
          axisLine: {
            lineStyle: {
              color: '#DDDDDD'
            }
          },
          axisLabel: {
            show: true,
            margin: 15,
            textStyle: {
              color: '#999999'
            }
          },
          data: xData
        },
        yAxis: {
          type: 'value',
          axisLine: {
            show: false,
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.45)'
            }
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(0, 0, 0, 0.15)'
            }
          }
        },
        series: [{
          name: name,
          type: 'line',
          symbolSize: 0,
          itemStyle: {
            normal: {
              borderWidth: 2
            }
          },
          data: yData
        }]
      }

      this.echart.setOption(option)
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  // 判断展示字段
  getFind (str: string) {
    return this.finds.find((item) => item === str)
  }
}
